type BoldTextProps = {
  children: React.ReactNode;
  className?: string;
};

const BoldText = ({
  children, 
  className,
}: BoldTextProps) => {
  const css = className ?? "";
  return (
    <span 
      className={`${css} text-black font-gill-sans text-base md:text-lg lg:text-xl break-words`}
    >
      {children}
    </span>
  );
}

export default BoldText;