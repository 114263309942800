import React from 'react';
import ListAnimation from '../../../animations/list-animation';
import Paragraph from '../text/paragraph';

export type CircleStepsType = {
  text: string;
  textBold?: string;
}

type CircleListedTextProps = {
  className?: string;
  steps: CircleStepsType[];
};

const CircleListedText = ({
  className,
  steps
}: CircleListedTextProps) => {
  const css = className ?? "";
  return (
    <>
      <div className='flex flex-col'>
      {
        steps.map((e, index) => (
          <React.Fragment key={index}>
            <ListAnimation xInit='50vw'>
              <div className='flex flex-row items-center py-2 lg:py-4'>
                <div
                  className={`${css} flex flex-none w-12 h-12 m-2 p-2 justify-center items-center rounded-full bg-blue-soft text-white text-xl font-extrabold md:m-4 md:p-4 md:w-16 md:h-16 md:text-3xl`}
                >
                  {index + 1}
                </div>
                <div>
                  <Paragraph>{e.text}</Paragraph>
                  {
                    e.textBold && (
                      <Paragraph className='font-extrabold'>{e.textBold}</Paragraph>
                    )
                  }
                </div>
              </div>
            </ListAnimation>
          </React.Fragment>
        ))
      }
      </div>
    </>
  );
}

export default CircleListedText;