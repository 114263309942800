type ContainerProps = {
    children: React.ReactNode;
    className?: string;
    name?: string;
};

const Container = ({
    children, 
    className,
    name
}: ContainerProps) => {
    const css = className ?? "";
    return (
        <div id={name} className={`${css} px-4 py-8 text-center md:px-8 md:py-16 lg:text-start max-w-screen-2xl`}>
            {children}
        </div>
    );
}

export default Container;