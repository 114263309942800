
import { useFormik } from 'formik';
import { Report } from 'notiflix';
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import TextAnimation from '../../animations/text-animation';
import MobileImg from "../../assets/images/form.png";
import { useApis } from '../../providers/apis-provider';
import { mapPostInterest } from '../../services/credits.service';
import RoundedButton from '../generic/buttons/rounded-button';
import ContentContainer from '../generic/containers/content-container';
import LabelValidation from '../generic/containers/label-validation';
import GenericInput from '../generic/inputs/generic-input';
import Paragraph from "../generic/text/paragraph";
import SecondTextHeader from '../generic/text/second-text-header';
import { mapFormToDto } from './utils';

type FormComponentProps = {
  title: string;
  titlePrimary: string;
  subTitle: string;
}

const FormComponent = ({
  titlePrimary,
  title,
  subTitle,
}: FormComponentProps) => {

  const { newRequest } = useApis();

  const navigate = useNavigate();
  const { state } = useLocation();

  const formik = useFormik({
    initialValues: {
      nombre: '',
      apellidoPaterno: '',
      apellidoMaterno: '',
      correoElectronico: '',
      telefono: '',
      nombreNegocio: '',
      codigoPostal: '',
      estado: '',
      municipio: '',
      cotizacion: {
        montoCredito: 0,
        tasaInteres: 0,
        numPagos: 0,
        esquemasPago: "PAGOS_DIARIOS"
      }
    },
    validationSchema: Yup.object().shape({
      nombre: Yup.string()
        .min(3, 'El campo debe tener por lo menos 3 caracteres')
        .required('El campo es requerido'),
      apellidoPaterno: Yup.string()
        .min(3, 'El campo debe tener por lo menos 3 caracteres')
        .required('El campo es requerido'),
      nombreNegocio: Yup.string()
        .min(3, 'El campo debe tener por lo menos 3 caracteres')
        .required('El campo es requerido'),
      telefono: Yup.string()
        .min(10, 'El teléfono debe ser de 10 digitos')
        .max(10, 'El teléfono debe ser de 10 digitos')
        .required('El campo es requerido'), 
      codigoPostal: Yup.string()
        .min(5, 'El código postal debe ser de 5 digitos')
        .max(5, 'El código postal debe ser de 5 digitos')
        .required('El campo es requerido'), 
      correoElectronico: Yup.string()
        .email('Correo electrónico invalido')
        .required('El campo es requerido'),
    }),
    onSubmit: (values) => {
      const dto = mapFormToDto(values, state);
      newRequest(mapPostInterest(dto))
      .then(() => 
        Report.success(
          '¡Recibimos tu información!',
          'En poco tiempo nos pondremos en contacto contigo para continuar tú solicitud',
          'Terminar',
          () => navigate("/")
        )
      )
      .catch(() => 
        Report.failure(
          '¡Ocurrio un error!',
          'No pudimos recibir tu información, por favor valida tus datos y vuelve a intentarlo',
          'Entendido',
        )
      );
    }
  });

  return (
    <>
      <div  id="form" className={`flex flex-col justify-between h-full lg:h-[900px] max-w-screen-2xl lg:items-center lg:flex-row lg:mt-12`}>
        <div className="text-center lg:text-left h-full w-[-webkit-fill-available] bg-gray-soft flex justify-center">
          <ContentContainer className='lg:!text-center flex flex-initial flex-col justify-center items-center'>

            <TextAnimation>
              <SecondTextHeader className="pt-12 text-center">
                {title}
                <span className="text-purple-primary">{titlePrimary}</span>
              </SecondTextHeader>
            </TextAnimation>
            <Paragraph className="text-center my-4"> {subTitle} </Paragraph>

            <form 
              className='w-full md:w-3/4' 
              onSubmit={formik.handleSubmit}
            >
              <GenericInput
                label='Nombre:'
                id="nombre" 
                type="text" 
                name="nombre" 
                placeholder="Ej. Juan" 
                onChange={formik.handleChange} 
                value={formik.values.nombre}
                onBlur={formik.handleBlur}
              >
              {
                formik.errors.nombre && formik.touched.nombre && (
                  <LabelValidation>{formik.errors.nombre}</LabelValidation>
                )
              }
              </GenericInput>

              <GenericInput
                label='Apellido paterno:'
                id="apellidoPaterno" 
                type="text" 
                name="apellidoPaterno" 
                placeholder="Ej. Pérez" 
                onChange={formik.handleChange} 
                value={formik.values.apellidoPaterno}
                onBlur={formik.handleBlur}
              >
              {
                formik.errors.apellidoPaterno && formik.touched.apellidoPaterno && (
                  <LabelValidation>{formik.errors.apellidoPaterno}</LabelValidation>
                )
              }
              </GenericInput>

              <GenericInput
                label='Apellido materno:'
                id="apellidoMaterno" 
                type="text" 
                name="apellidoMaterno" 
                placeholder="Ej. Pérez" 
                onChange={formik.handleChange} 
                value={formik.values.apellidoMaterno}
              >
              {
                formik.errors.apellidoMaterno && formik.touched.apellidoMaterno && (
                  <LabelValidation>{formik.errors.apellidoMaterno}</LabelValidation>
                )
              }
              </GenericInput>

              <GenericInput
                label='Correo:'
                id="correoElectronico" 
                type="email" 
                name="correoElectronico" 
                placeholder="Ej. ejemplo@email.com" 
                onChange={formik.handleChange} 
                value={formik.values.correoElectronico}
                onBlur={formik.handleBlur}
              >
              {
                formik.errors.correoElectronico && formik.touched.correoElectronico && (
                  <LabelValidation>{formik.errors.correoElectronico}</LabelValidation>
                )
              }
              </GenericInput>

              <GenericInput
                label='Teléfono:'
                id="telefono" 
                type="number" 
                name="telefono" 
                placeholder="Ej. 771 111 1111" 
                onChange={formik.handleChange} 
                value={formik.values.telefono}
                onBlur={formik.handleBlur}
              >
              {
                formik.errors.telefono && formik.touched.telefono && (
                  <LabelValidation>{formik.errors.telefono}</LabelValidation>
                )
              }
              </GenericInput>

              <GenericInput
                label='Código postal:'
                id="codigoPostal" 
                type="number" 
                name="codigoPostal" 
                placeholder="Ej. 42080" 
                onChange={formik.handleChange} 
                value={formik.values.codigoPostal}
                onBlur={formik.handleBlur}
              >
              {
                formik.errors.codigoPostal && formik.touched.codigoPostal && (
                  <LabelValidation>{formik.errors.codigoPostal}</LabelValidation>
                )
              }
              </GenericInput>

              <GenericInput
                label='Nombre del negocio:'
                id="nombreNegocio" 
                type="text" 
                name="nombreNegocio" 
                placeholder="Ej. Juan" 
                onChange={formik.handleChange} 
                value={formik.values.nombreNegocio} 
              >
              {
                formik.errors.nombreNegocio && formik.touched.nombreNegocio && (
                  <LabelValidation>{formik.errors.nombreNegocio}</LabelValidation>
                )
              }
              </GenericInput>

              <div className='mx-auto py-8 text-center'>
                <RoundedButton type='submit'> {"Enviar"} </RoundedButton>
              </div>
            </form>
          </ContentContainer>
        </div>
        <img src={MobileImg} alt="" className='h-0 lg:h-full'/>
      </div>
    </>
  );
};

export default FormComponent;