import { RequestModel } from "../../model/request-model";


export const mapFormToDto = (values: any, state: any):RequestModel => ({
    nombre: values.nombre,
    apellidoPaterno: values.apellidoPaterno,
    apellidoMaterno: values.apellidoMaterno,
    correoElectronico: values.correoElectronico,
    telefono: values.telefono,
    nombreNegocio: values.nombreNegocio,
    codigoPostal: values.codigoPostal,
    estado: 'Sin definir', //FIXME:
    municipio: 'Sin definir', //FIXME:
    cotizacion: {
        montoCredito: state.amount,
        tasaInteres: state.interestRate,
        numPagos: state.paymentsNumber,
        esquemaPago: state.paymentScheme === 'D' ? "PAGOS_DIARIOS" : "PAGOS_SEMANALES" //FIXME:
    }
});