import React from 'react';
import ListAnimation from '../../../animations/list-animation';
import Paragraph from '../text/paragraph';

export type RectangleStepsType = {
  text: string;
  textBold?: string;
}

type RectangleListedTextProps = {
  className?: string;
  steps: RectangleStepsType[];
};

const RectangleListedText = ({
  className,
  steps
}: RectangleListedTextProps) => {
  const css = className ?? "";
  return (
    <React.Fragment>
    {
      steps.map((e, index) => (
        <React.Fragment key={index}>
          <ListAnimation xInit='-50vw'>
            <div className={`${css} flex flex-col items-center py-2 md:py-4 lg:py-6 lg:items-baseline`}>
              <div
                className={`flex flex-none w-20 h-20 m-2 p-2 justify-center items-center rounded-2xl bg-blue-soft text-white text-5xl font-extrabold
                                md:m-4 md:p-4 md:w-32 md:h-32 md:text-6xl`}
              >
                {index + 1}
              </div>
              <div>
                <Paragraph className='text-center lg:text-left'>{e.text}</Paragraph>
                {
                  e.textBold && (
                    <Paragraph className='font-extrabold'>{e.textBold}</Paragraph>
                  )
                }
              </div>
            </div>
          </ListAnimation>
        </React.Fragment>
      ))
    }
    </React.Fragment>
  );
}

export default RectangleListedText;