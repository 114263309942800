import TextAnimation from '../../animations/text-animation';
import Container from '../generic/containers/container';
import SecondTextHeader from '../generic/text/second-text-header';
import VideoEmbeded from '../generic/video/video-embeded';

const WhyUsComponent = () => {
  return (
    <>
      <Container
        name='why-us'
        className='bg-contain bg-no-repeat bg-diamond-gray-left my-8 p-4 selection:h-fit lg:bg-[80vw, 100vh] lg:h-fit md:p-12 lg:p-16'
      >
        <TextAnimation>
          <SecondTextHeader className='my-4 pb-4 text-center'>
            {'¿Por qué '}
            <span className='text-purple-primary'>{'point.mx'}</span>
            {'?'}
          </SecondTextHeader>
        </TextAnimation>
        <VideoEmbeded
          url="https://www.youtube.com/embed/iR8pQjnUT5g?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com"
          background="transparent"
        />
      </Container>
    </>
  )
}

export default WhyUsComponent
