
import TextAnimation from '../../animations/text-animation';
import MobileImg from "../../assets/images/hero-form.png";
import ContentContainer from '../generic/containers/content-container';
import LeftColumn from "../generic/containers/left-colum";
import RightColumn from '../generic/containers/right-colum';
import TwoColumnImage from '../generic/containers/two-column-image';
import MobileImage from '../generic/images/mobile-image';
import Paragraph from "../generic/text/paragraph";
import TextHeader from "../generic/text/text-header";

type HeroFormComponentProps = {
  title: string;
  titlePrimary: string;
  subTitle: string;
}

const HeroFormComponent = ({
  titlePrimary,
  title,
  subTitle,
}: HeroFormComponentProps) => {

  return (
    <>
      <div className="flex h-32" />
      <TwoColumnImage
        name='hero-form'
        className="bg-right lg:bg-hero-form md:mt-[-8rem]"
      >
        <LeftColumn className='lg:!pr-0 lg:pl-12'>
          <ContentContainer className='md:mt-28'>
            <TextAnimation>
              <TextHeader>
                {title}
                <span className="text-purple-primary">{titlePrimary}</span>
              </TextHeader>
            </TextAnimation>
            <MobileImage img={MobileImg} className='!ml-0 !mr-[2rem]' />
            <Paragraph className="text-center my-8 md:mr-20 lg:mr-0 lg:text-left">
              {subTitle}
            </Paragraph>
          </ContentContainer>
        </LeftColumn>
        {/* <RightColumn className="mx-4 mt-12 md:mt-0 md:mx-8 md:py-20"/> */}
        <RightColumn />
      </TwoColumnImage>
    </>
  );
};

export default HeroFormComponent;