import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { createContext, useContext, useState } from "react";
import { ClockLoader } from "react-spinners";
import { ApiModel } from "../model/apis/apis-model";
import { getApi, postApi } from '../services/apis-service';
import { authToken } from '../services/auth-service';
import { useStorage } from "./storage-provider";

export type ApisContextProps = {
  isLoading: (loading: boolean) => void;
  newRequest: (request: ApiModel) => Promise<void | AxiosResponse<any, any>>;
}

type ApisProviderProps = {
  children: React.ReactNode;
};

const defaultContext: ApisContextProps = {
  isLoading: () => {},
  newRequest: () => new Promise(resolve => resolve()),
};

const ApisContext = createContext<ApisContextProps>(defaultContext);

const ApisProvider = ({
  children
}: ApisProviderProps) => {

  const [loader, setLoader] = useState<boolean>(false);
  const { token, setToken } = useStorage();
  
  const { enqueueSnackbar } = useSnackbar();

  const isLoading = (loading: boolean) => {
    setLoader(loading);
  }

  const newRequest = (
    request: ApiModel
  ): Promise<void | AxiosResponse<any, any>> => {

    setLoader(true);

    if (!token.accessToken) {
      return getAccessToken(request)
        .finally(() => setLoader(false));
    } else {
      return getApiType(token.accessToken, request)
        .catch((error) => {
          if (error.response.status === 401 || !token.accessToken) {
            return getAccessToken(request)
          } else {
            enqueueSnackbar(
              error.message,
              { variant: 'error' }
            );
            throw Error(error);
          }
        })
        .finally(() => setLoader(false));
    }
  }

  const getAccessToken = (
    request: ApiModel
  ): Promise<void | AxiosResponse<any, any>> => {
    return authToken()
    .then((response) => {
      setToken({ accessToken: response.data.access_token })
      return retryRequest(request, response.data.access_token);
    })
    .catch((err) => {
      enqueueSnackbar(
        `${err.response.data.codigo} - ${err.response.data.detalles[0].mensaje}`,
        { variant: 'error' }
      );
      throw Error(err);
    });
  }

  const retryRequest = (
    request: ApiModel,
    newAccessToken: string
  ): Promise<void | AxiosResponse<any, any>> => {
    return getApiType(newAccessToken, request)
      .catch((error) => {
        enqueueSnackbar(
          error.message,
          { variant: 'error' }
        );
        throw Error(error);
      });
  }

  const context = ({
    isLoading,
    newRequest
  });

  return (
    <ApisContext.Provider value={context}>
    <>
      {
        loader && (
          <div className="w-full h-full bg-gray-soft fixed top-0 left-0 z-40">
            <div className="fixed top-1/2 left-1/2 z-50 translate-x-[-50%] translate-y-[-50%]">
              <ClockLoader loading={loader}></ClockLoader>
            </div>
          </div>
        )
      }
      {children}
    </>
    </ApisContext.Provider>
  ); 
}

export const useApis = () => {
  return useContext(ApisContext);
};

export default ApisProvider;

export const getApiType = (
  accessToken: string,
  request: ApiModel,
): Promise<AxiosResponse<any, any>> => {
  switch (request.verb) {
    case "POST":
      return postApi(accessToken, request);
    case "GET":
      return getApi(accessToken, request);
    default:
      return getApi(accessToken, request);
  }
}