
type RoundedButtonProps = {
    children: React.ReactNode;
    className?: string;
    type?: 'submit' | 'reset' | 'button' | undefined;
    onClick?: () => void;
};

const RoundedButton = ({
    children, 
    className,
    type,
    onClick,
}: RoundedButtonProps) => {
    const css = className ?? "";
    return (
        <button 
            className={`${css} rounded-full bg-purple-primary py-3 px-8
                font-extrabold font-barlow text-white text-center text-base md:text-xl lg:text-2xl`}
            onClick={onClick}
            type={type}
        >
            {children}
        </button>
    );
}

export default RoundedButton;