import axios from "axios";
import { Config } from '../utils/environment-constants';

export type authUserType = {
  username: string;
  password: string;
}

export const authToken = async () => {
  const data = `grant_type=client_credentials`
  return axios.post(
    `${Config.API.URL}/security/oauth/token`,
    data,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      auth: {
        username: Config.API.USER_NAME,
        password: Config.API.PASSWORD
      }
    }
  );
}