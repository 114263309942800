import { useNavigate } from "react-router-dom";
import TextAnimation from '../../animations/text-animation';
import phone from "../../assets/images/phone-half.png";
import LinearButton from '../generic/buttons/linear-button';
import Container from '../generic/containers/container';
import ContentContainer from "../generic/containers/content-container";
import LeftColumn from "../generic/containers/left-colum";
import RightColumn from '../generic/containers/right-colum';
import TwoColumn from "../generic/containers/two-column";
import RectangleListedText, { RectangleStepsType } from '../generic/lists/rectangle-listed-text';
import SecondTextHeader from "../generic/text/second-text-header";

type StepsJoinComponentProps = {
  firstTitlePrimary: string;
  secondTitlePrimary: string;
  title: string;
  steps: RectangleStepsType[];
  button: string;
}

const StepsJoinComponent = ({
  firstTitlePrimary,
  secondTitlePrimary,
  title,
  steps,
  button,
}: StepsJoinComponentProps) => {

  const navigate = useNavigate();

  return (
    <>
      <Container name='steps-join' className="bg-gray-soft p-8">
        <TwoColumn>
          <LeftColumn>
            <ContentContainer>
              <SecondTextHeader className="md:my-4 pb-4 text-center md:pb-0 lg:text-left">
                <span className="text-purple-primary">{firstTitlePrimary}</span>
                {` ${title} `}
                <span className="text-purple-primary">{secondTitlePrimary}</span>
              </SecondTextHeader>
              {
                button && (
                  <TextAnimation>
                    <LinearButton className="my-6" onClick={() => navigate("/cotizador")}>
                      {button}
                    </LinearButton>
                  </TextAnimation>
                )
              }
              <div className="flex justify-center">
                <img src={`${phone}`} alt="" className="w-[40vw] lg:w-[20vw]" />
              </div>
            </ContentContainer>
          </LeftColumn>
          <RightColumn>
            <div className="absolute top-0 border-gray-soft-hover lg:border-x lg:h-full" />
            <RectangleListedText steps={steps} className='px-8' />
          </RightColumn>
        </TwoColumn>
      </Container>
    </>
  );
};

export default StepsJoinComponent;