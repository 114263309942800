import { Navigate, Route, Routes } from 'react-router-dom'
import FormPage from '../pages/form-page'
import HomePage from '../pages/home-page'
import PoliciesPage from '../pages/policies-page'
import QuotationPage from '../pages/quotation-page'
import { ConstantsRoutes } from './constants-routes'

const RoutesPages = () => {
  return (
    <Routes>
      <Route 
        path='/' 
        element={<HomePage />} 
      />
      <Route 
        path={ConstantsRoutes.QUOTATION} 
        element={<QuotationPage />} 
      />
      <Route 
        path={ConstantsRoutes.QUOTATION_FORM} 
        element={<FormPage />} 
      />
      <Route 
        path={ConstantsRoutes.POLICIES} 
        element={<PoliciesPage />} 
      />
      <Route 
        path='*' 
        element={<Navigate to={'/'} replace />} 
      />
    </Routes>
  )
}

export default RoutesPages
