import React, { ReactElement } from 'react';
import CountAnimation from '../../../animations/count-animation';
import Paragraph from '../text/paragraph';
import SecondTextHeader from '../text/second-text-header';

export type IconCardType = {
  count: number;
  body: string;
  icon: ReactElement<any, any>;
}

type IconCardProps = {
  className?: string;
  cards: IconCardType[];
};

const IconCard = ({
  className,
  cards
}: IconCardProps) => {
  const css = className ?? "";
  return (
    <React.Fragment>
      <div className='flex flex-col justify-center md:flex-row'>
      {
        cards.map((e, index) => (
          <React.Fragment key={index}>
            <div className={`${css} flex flex-col items-center justify-center bg-gray-soft rounded-3xl m-4 p-8 cursor-pointer md:p-12 hover:bg-gray-soft-hover`}>
              {e.icon}
              <SecondTextHeader className='!text-purple-primary md:py-4'>
                <CountAnimation 
                  prefix='+'
                  start={0}
                  end={e.count}
                />
              </SecondTextHeader>
              <Paragraph className='font-bold md:text-center md:py-4'>{e.body}</Paragraph>
            </div>
          </React.Fragment>
        ))
      }
      </div>
    </React.Fragment>
  );
}

export default IconCard;