import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './components/shared/layout/layout';
import ApisProvider from './providers/apis-provider';
import StorageProvider from './providers/storage-provider';
import RoutesPages from './routes/routes-pages';

function App() {
  return (
    <StorageProvider>
      <SnackbarProvider 
        variant="success"
        autoHideDuration={3000}
      >
        <BrowserRouter>
          <Routes>
            <Route 
              path="/*" 
              element={ 
                <ApisProvider>
                  <Layout>
                    <RoutesPages />
                  </Layout>
                </ApisProvider> 
              }
            />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </StorageProvider>
  );
}

export default App;
