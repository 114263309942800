type LeftColumnProps = {
    children?: React.ReactNode;
    className?: string;
};

const LeftColumn = ({
    children, 
    className,
}: LeftColumnProps) => {
    const css = className ?? "";
    return (
        <div className={`flex-shrink-0 text-center lg:w-6/12 lg:pr-12 lg:text-left ${css}`}>
            {children}
        </div>
    );
}

export default LeftColumn;