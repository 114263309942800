type DesktopNavLinksProps = {
    children: React.ReactNode;
    className?: string;
};

const DesktopNavLinks = ({
    children,
    className,
}: DesktopNavLinksProps) => {

    const css = className ?? "";

    return (
        <div 
            className={`hidden md:flex flex-1 justify-between items-center ${css}`}
        >
            {children}
        </div>
    );
};

export default DesktopNavLinks;