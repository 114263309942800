type ParagraphProps = {
    children: React.ReactNode;
    className?: string;
};

const Paragraph = ({
    children, 
    className,
}: ParagraphProps) => {
    const css = className ?? "";
    return (
        <p className={`${css} text-left text-xl font-normal font-barlow text-gray-hard md:text-3xl`}>
            {children}
        </p>
    );
}

export default Paragraph;