
type MobileImageProps = {
    className?: string;
    img: string;
};

const MobileImage = ({
    className,
    img,
}: MobileImageProps) => {
    const css = className ?? "";
    return (
        <div className={`w-screen mx-[-2rem] my-4 lg:w-0 ${css}`}>
            <img className="w-full" src={img} alt="" />
        </div>
    );
}

export default MobileImage;