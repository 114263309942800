type LabelValidationProps = {
    children: React.ReactNode;
    className?: string;
    name?: string;
};

const LabelValidation = ({
    children, 
    className,
    name
}: LabelValidationProps) => {
    const css = className ?? "";
    return (
        <div id={name} className={`text-base md:text-lg text-red-primary text-right ${css}`}>
            {children}
        </div>
    );
}

export default LabelValidation;