import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { useCountUp } from 'react-countup';
import { useInView } from "react-intersection-observer";

type CountAnimationProps = {
  start: number;
  end: number;
  prefix: string;
}
const CountAnimation = ({
  prefix,
  start: startValue,
  end: endValue
}: CountAnimationProps) => {

  const { ref, inView } = useInView();
  const countUpRef = React.useRef(null);
  
  const { start, reset } = useCountUp({
    ref: countUpRef,
    start: startValue,
    end: endValue,
    prefix: prefix ?? "",
    duration: 2,
    onReset: () => console.log('Resetted!'),
    onStart: ({ pauseResume }) => console.log(pauseResume),
  })

  useEffect(
    () => {;
      if (inView) {
        start();
      } else {
        reset();
      }
    }, 
    [inView, reset, start]
  );
  
  return (
    <motion.div 
      ref={ref} 
      initial="hidden"
    >
      <div ref={countUpRef} />
    </motion.div>
  )
}

export default CountAnimation;