import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Card, CardActions, CardContent, Collapse, IconButton } from "@mui/material";
import React, { Fragment } from "react";
import ListAnimation from '../../../animations/list-animation';
import BoldText from '../text/bold-text';

export type CardProps = {
  id: number;
  title: string;
  children: React.ReactNode;
}

type ExpandCardsProps = {
  cards: CardProps[];
}

const ExpandCards = ({ cards }: ExpandCardsProps) => {

  const [expandedId, setExpandedId] = React.useState(-1);

  const handleExpandClick = (i:number) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  return (
    <Fragment>
      <div className="my-4 flex justify-center">
        {cards.map((card, i) => (
          <div className={'w-full max-w-screen-lg'}>
            <ListAnimation xInit='-50vw'>
              <Card 
                key={card.id}
                style={{ borderRadius: '30px', borderStyle: 'solid', borderWidth: '2px' }} // FIXME: Syles
              >

                <CardActions 
                  disableSpacing
                  className="flex justify-between mx-8"
                >
                  <BoldText> { card.title } </BoldText>
                  <IconButton
                    onClick={() => handleExpandClick(i)}
                    aria-expanded={expandedId === i}
                    aria-label="show more"
                  >
                    {
                      expandedId === -1 
                        ? ( <KeyboardArrowDownIcon style={{ color: 'black'}} /> ) // FIXME: Syles
                        : ( <KeyboardArrowUpIcon style={{ color: 'black'}} /> ) // FIXME: Syles
                    }
                  </IconButton>
                </CardActions>

                <Collapse in={expandedId === i} timeout="auto" unmountOnExit>
                  <CardContent>
                    {card.children}
                  </CardContent>
                </Collapse>

              </Card>
            </ListAnimation>
          </div>
        ))}
      </div>
    </Fragment>
  );
}

export default ExpandCards;