import MobileImg from "../../assets/images/quotation.png";
import ContentContainer from "../generic/containers/content-container";
import LeftColumn from "../generic/containers/left-colum";
import RightColumn from '../generic/containers/right-colum';
import TwoColumnImage from "../generic/containers/two-column-image";
import MobileImage from '../generic/images/mobile-image';
import CheckText from '../generic/lists/check-text';
import Paragraph from "../generic/text/paragraph";
import TextHeader from "../generic/text/text-header";

type HeroComponentProps = {
  titlePrimary: string;
  title: string;
  subTitle: string;
  checkListText: string[];
}

const HeroComponent = ({
  titlePrimary,
  title,
  subTitle,
  checkListText,
}: HeroComponentProps) => {

  return (
    <>
      <div className="flex h-32" />
      <TwoColumnImage
        name='hero'
        className="lg:bg-quotation-background md:mt-[-8rem] md:ml-[-1rem]"
      >
        <LeftColumn />
        <RightColumn className="mx-4 md:mt-8 md:mx-8 md:pt-16">
          <ContentContainer className="md:mt-8 lg:!px-0">
            <TextHeader className="pb-4">
              <span className="text-purple-primary">{titlePrimary}</span>
              {title}
            </TextHeader>
            <MobileImage img={MobileImg} />
            <Paragraph className="my-8 lg:mr-0">
              {subTitle}
            </Paragraph>
            <CheckText checks={checkListText} xInit={"-50vw"} />
          </ContentContainer>
        </RightColumn>
      </TwoColumnImage>
    </>
  );
};

export default HeroComponent;