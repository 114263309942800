type TwoColumnProps = {
    children: React.ReactNode;
    className?: string;
    name?: string;
};

const TwoColumn = ({
    children, 
    className,
    name
}: TwoColumnProps) => {
    const css = className ?? "";
    return (
        <div 
            id={name}
            className={`flex flex-col h-fit max-w-screen-2xl mx-auto ${css} 
                lg:items-center lg:flex-row`}  
        >
            {children}
        </div>
    );
}

export default TwoColumn;