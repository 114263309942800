import { useNavigate } from 'react-router-dom';
import MobileImg from "../../assets/images/benefits.png";
import RoundedButton from '../generic/buttons/rounded-button';
import ContentContainer from '../generic/containers/content-container';
import LeftColumn from "../generic/containers/left-colum";
import RightColumn from '../generic/containers/right-colum';
import TwoColumnImage from '../generic/containers/two-column-image';
import MobileImage from '../generic/images/mobile-image';
import CheckText from '../generic/lists/check-text';
import SecondTextHeader from '../generic/text/second-text-header';

type BenefitsComponentProps = {
  titlePrimary: string;
  title: string;
  checkListText: string[];
  button?: string;
}

const BenefitsComponent = ({
  titlePrimary,
  title,
  checkListText,
  button,
}: BenefitsComponentProps) => {

  const navigate = useNavigate();

  return (
    <>
      <div id='benefits' className='md:my-16 lg:my-28'>
        <TwoColumnImage
          className="bg-right lg:bg-benefits-background"
        >
          <LeftColumn className='lg:px-8'>
            <ContentContainer>
              <SecondTextHeader className="my-4 pb-4">
                <span className="text-purple-primary">{titlePrimary}</span>
                {title}
              </SecondTextHeader>
              <MobileImage img={MobileImg} className="!ml-0 !mr-[2rem]" />
              <div className='inline-block mx-auto'>
                <CheckText checks={checkListText} xInit={"50vw"} />
              </div>
              {
                button && (
                  <div className='bloc'>
                    <RoundedButton className="my-6 md:my-8" onClick={() => navigate("/cotizador")}>
                      {button}
                    </RoundedButton>
                  </div>
                )
              }
            </ContentContainer>
          </LeftColumn>
          <RightColumn />
        </TwoColumnImage>
      </div>
    </>
  );
};

export default BenefitsComponent;