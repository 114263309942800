type ContentContainerProps = {
  children: React.ReactNode;
  className?: string;
  name?: string;
};

const ContentContainer = ({
  children,
  className,
  name
}: ContentContainerProps) => {
  const css = className ?? "";
  return (
    <div id={name} className={`p-4 text-center lg:px-4 lg:text-start ${css}`}>
      {children}
    </div>
  );
}

export default ContentContainer;