import { useEffect, useState } from "react";

const getInitialState = <S>(
  key:string,
  initialState:  S | (() => S)
) => {
  const state = sessionStorage.getItem(key)
  return state ? parse(state) : initialState
}


const useLocalStorage = <S>(
  key: string,
  initialState?: S | (() => S)
): [S, React.Dispatch<React.SetStateAction<S>>] => {

  const [state, setState] = useState<S>( getInitialState(key, initialState) as S );

  useEffect(
    () => {
      const item = sessionStorage.getItem(key);
      if (item) setState(parse(item));
    // eslint-disable-next-line  
    }, []
  );

  useEffect(
    () => {
      sessionStorage.setItem(key, JSON.stringify(state));
    // eslint-disable-next-line
    }, [state]
  );

  return [state, setState];
};

const parse = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

export default useLocalStorage;