import { useNavigate } from 'react-router-dom';
import TextAnimation from '../../../animations/text-animation';
import RoundedButton from '../../generic/buttons/rounded-button';

const IWantIt = () => {
  const navigate = useNavigate()

  return (
    <div
      className={`max-w-screen-2xl fixed w-full bottom-0 h-24 z-20 shadow-inner flex justify-center items-center shadow-gray-soft bg-white md:invisible`}
    >
      <TextAnimation>
        <RoundedButton onClick={() => navigate('/cotizador')}>
          {'Cotiza tu crédito'}
        </RoundedButton>
      </TextAnimation>
    </div>
  )
}

export default IWantIt
