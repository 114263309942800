import { ForwardRefRenderFunction, InputHTMLAttributes } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name?: string;
    ref?: string;
    label: string;
    children?: React.ReactNode;
}

const GenericInput: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({
    label,
    name,
    ref,
    children,
    ...otherProps
}:InputProps) => {
    return (
        <div className='p-2 w-full'>
            <div className={`flex flex-col md:flex-row flex-initial py-1`}>
                <label className={"flex-initial text-left text-xl md:text-2xl font-normal font-barlow text-gray-hard pr-4"}>
                    {label}
                </label>
                <input
                    {...otherProps}
                    className={
                        children
                            ? `flex-auto px-2 py-1 rounded-lg border-2 border-red-primary bg-transparent font-barlow text-xl md:text-2xl text-red-primary focus-visible:outline-red-primary`
                            : `flex-auto px-2 py-1 rounded-lg border border-gray-hard bg-transparent font-barlow text-xl md:text-2xl focus-visible:outline-purple-primary`
                    }
                    id={name}
                    name={name}
                    ref={ref}
                />
            </div>
            {children}
        </div>
    );
};

export default GenericInput;