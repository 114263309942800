type TwoColumnImageProps = {
    children: React.ReactNode;
    className?: string;
    name?: string;
};

const TwoColumnImage = ({
    children, 
    className,
    name,
}: TwoColumnImageProps) => {
    const css = className ?? "";
    return (
        <div 
            id={name}
            className={`${css} flex flex-col max-w-screen-2xl mx-auto bg-contain bg-no-repeat bg-transparent selection:h-fit
                lg:py-20 lg:items-center lg:flex-row lg:h-screen lg:max-h-[860px] lg:min-h-[600px]`}  
        >
            {children}
        </div>
    );
}

export default TwoColumnImage;