import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

type TextAnimationProps = {
  children: React.ReactNode;
}
const TextAnimation = ({
  children
}: TextAnimationProps) => {

  const animation = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.1 });

  const variants = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { 
        duration: 0.8, 
        delayChilden: 0.2, 
        staggerChildren: 0.1 
      },
    },
    hidden: {
      opacity: 0,
    },
  }
  
  useEffect(
    () => {
      if (inView) {
        animation.start("visible");
      } else {
        animation.start("hidden");
      }
    }, 
    [inView, animation]
  );
  
  return (
    <motion.div 
      ref={ref} 
      animate={animation}
      initial="hidden"
      variants={variants}
    >
      { children }
    </motion.div>
  )
}

export default TextAnimation;