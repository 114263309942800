import { useEffect } from 'react';
import FormComponent from '../components/form/form';
import HeroFormComponent from '../components/form/hero-form';

const FormPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <HeroFormComponent
        title='Estás a un paso de solicitar tu '
        titlePrimary='préstamo'
        subTitle='Para poder continuar con tu trámite necesitamos que nos compartas la siguiente información'
      />
      <FormComponent
        title='Solicitud de '
        titlePrimary='préstamo'
        subTitle='Actualmente solo contamos con cobertura en Pachuca, Tecamac y Teotihuacán'
      />
    </div>
  );
};

export default FormPage;