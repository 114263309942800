import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

type ListAnimationProps = {
  children: React.ReactNode;
  xInit: string;
}
const ListAnimation = ({
  children,
  xInit,
}: ListAnimationProps) => {

  const animation = useAnimation();
  const { ref, inView } = useInView();

  const variants = {
    visible: {
      x: 0,
      transition: {
        type: 'spring', 
        duration: 2, 
        bounce: 0
      }
    },
    hidden: {
      x: xInit,
    },
  }
  
  useEffect(
    () => {
      if (inView) {
        animation.start("visible");
      } else {
        animation.start("hidden");
      }
    }, 
    [inView, animation]
  );
  
  return (
    <motion.div 
      ref={ref} 
      animate={animation}
      initial="hidden"
      variants={variants}
    >
      { children }
    </motion.div>
  )
}

export default ListAnimation;