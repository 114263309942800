type NavLinkProps = {
  children: React.ReactNode;
  className?: string;
  href: string;
  target?: string;
}

const NavLink = ({ children, className, href, target }: NavLinkProps) => {
  const css = className ?? ''
  return (
    <a
      className={`text-lg my-2 text-gray-hard font-gill-sans font-semibold uppercase tracking-wide transition duration-300 pb-1 border-b-2 border-transparent 
        md:text-xl md:mx-6 md:my-0 hover:border-purple-primary focus:text-purple-primary hover:text-purple-primary ${css}`}
      href={href}
      target={target}
    >
      {children}
    </a>
  )
}

export default NavLink
