
type NavLinksProps = {
    children: React.ReactNode;
    className?: string;
};

const MobileNavLinksContainer = ({
    children,
    className,
}: NavLinksProps) => {
    const css = className ?? "";

    return (
        <nav className={`flex flex-1 items-center justify-between ${css}`}>
            {children}
        </nav>
    );
};

export default MobileNavLinksContainer;