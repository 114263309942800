import { FloatingWhatsApp } from 'react-floating-whatsapp-button';
import 'react-floating-whatsapp-button/dist/index.css';
import Footer from '../footer/footer';
import HeaderComponent from '../header/header-component';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({
    children
}: LayoutProps) => {
  return (
  <>
    <div className='max-w-screen-2xl mx-auto'>
      <div className='font-display min-h-screen text-secondary-500 overflow-hidden'>    
        <HeaderComponent/>
        {children}
        <Footer/>
        <FloatingWhatsApp 
          phone={"527711861415"}
          message={"Me gustaría poder obtener más información de los créditos que ofrecen."}
          headerTitle={"Point.mx"}
          popupMessage={"¿Puedo ayudarte?"}
          zIndex={30}
        />
      </div>
    </div>
  </>
  );
};

export default Layout;