import { useNavigate } from "react-router-dom";
import phone from "../../assets/images/phone.png";
import RoundedButton from '../generic/buttons/rounded-button';
import Container from "../generic/containers/container";
import ContentContainer from "../generic/containers/content-container";
import LeftColumn from "../generic/containers/left-colum";
import RightColumn from '../generic/containers/right-colum';
import TwoColumn from "../generic/containers/two-column";
import CircleListedText, { CircleStepsType } from "../generic/lists/circle-listed-text";
import SecondTextHeader from "../generic/text/second-text-header";

type StepsComponentProps = {
  firstAmount: string;
  secondAmount: string;
  steps: CircleStepsType[];
  button?: string;
}

const StepsComponent = ({
  firstAmount,
  secondAmount,
  steps,
  button,
}: StepsComponentProps) => {

  const navigate = useNavigate();

  return (
    <>
      <Container name='steps'>
        <ContentContainer className="lg:!text-center">
          <SecondTextHeader>
            {"Desde "}
            <span className="text-purple-primary">{firstAmount}</span>
            {" hasta "}
            <span className="text-purple-primary">{secondAmount}</span>
          </SecondTextHeader>
        </ContentContainer>
        <TwoColumn className="lg:!py-12">
          <LeftColumn>
            <ContentContainer>
              <CircleListedText steps={steps} />
              {
                button && (
                  <RoundedButton className="my-6" onClick={() => navigate("/cotizador")}>
                    {button}
                  </RoundedButton>
                )
              }
            </ContentContainer>
          </LeftColumn>
          <RightColumn 
            className="flex justify-center items-center bg-contain bg-no-repeat bg-blue-circle-left bg-right mt-8 h-fit w-full lg:mt-0 lg:flex-auto md:mr-[-2rem]"
          >
            <img src={phone} alt="" className="w-2/5" />
          </RightColumn>
        </TwoColumn>
      </Container>
    </>
  );
};

export default StepsComponent;