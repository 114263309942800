import TextAnimation from '../../animations/text-animation';
import MobileImg from "../../assets/images/hero-quotation.png";
import ContentContainer from '../generic/containers/content-container';
import LeftColumn from "../generic/containers/left-colum";
import RightColumn from '../generic/containers/right-colum';
import TwoColumnImage from '../generic/containers/two-column-image';
import MobileImage from '../generic/images/mobile-image';
import Paragraph from "../generic/text/paragraph";
import SecondTextHeader from '../generic/text/second-text-header';

type HeroQuotationComponentProps = {
  title: string;
  titlePrimary: string;
  subTitle: string;
}

const HeroQuotationComponent = ({
  titlePrimary,
  title,
  subTitle,
}: HeroQuotationComponentProps) => {

  return (
    <>
      <div className="flex h-32" />
      <TwoColumnImage
        name='hero-quotation'
        className="bg-right lg:bg-hero-quotation md:mt-[-8rem]"
      >
        <LeftColumn className='mx-4 md:mt-8 md:mx-8 md:pt-16'>
          <ContentContainer>
            <TextAnimation>
              <SecondTextHeader>
                {title}
                <span className="text-purple-primary">{titlePrimary}</span>
              </SecondTextHeader>
            </TextAnimation>
            <MobileImage img={MobileImg} />
            <Paragraph className="text-center my-8 md:mr-20 lg:mr-0 lg:text-left">
              {subTitle}
            </Paragraph>
          </ContentContainer>
        </LeftColumn>
        <RightColumn />
      </TwoColumnImage>
    </>
  );
};

export default HeroQuotationComponent;