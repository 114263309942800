import { useEffect } from 'react';
import ExpandCards from '../components/generic/cards/expand-cards';
import Container from '../components/generic/containers/container';
import SecondTextHeader from '../components/generic/text/second-text-header';
import PrivacyPolicy from "../components/policies/privacy-policy";

const PoliciesPage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
    <div className='h-36'/>
    <div className='flex justify-center items-baseline min-h-screen'>
      <Container>
        <div className='grid my-4'>
          <SecondTextHeader className='!text-purple-primary mb-12 text-center'>
            {"Revisa nuestras politícas de privacidad"}
          </SecondTextHeader>
          <ExpandCards
            cards={[
              {
                id: 1,
                title: "Aviso de privacidad",
                children: <PrivacyPolicy />
              }
            ]}
          />
        </div>
      </Container>
    </div>
    </>
  );
};

export default PoliciesPage;