
type NavLinksProps = {
    children: React.ReactNode;
    className?: string;
};

const NavLinks = ({
    children,
    className,
}: NavLinksProps) => {
    const css = className ?? "";

    return (
        <div className={`inline-block cursor-pointer ${css}`} >
            {children}
        </div>
    );
};

export default NavLinks;