type RightColumnProps = {
    children?: React.ReactNode;
    className?: string;
};

const RightColumn = ({
    children, 
    className,
}: RightColumnProps) => {
    const css = className ?? "";
    return (
        <div className={`${css} relative flex flex-col h-fit`}>
            {children}
        </div>
    );
}

export default RightColumn;