
const dev = {
  API: {
    URL: 'https://dev.ronquillo.tech:9090',
    USER_NAME: 'Pagina-Web',
    PASSWORD: 'TKJK0LU64jDNS+f1GM6qqHDyVF6LAnX2M/khAiYqYA/kha6XzryA6Mg0mMB3i8nd',
  },
  LINKS: {
    CLIENTS_PORTAL: 'https://dev.clientes.financierapoint.com.mx',
  }
};

const prod = {
  API: {
    URL: 'https://api.financierapoint.com.mx:9090',
    USER_NAME: 'Pagina-Web',
    PASSWORD: '7ZAwJLZJi9q0AwZNwUNF9hNKjLEAZYKZ9RVaZqNoqFwMhs2QLxnvh5haQY1jVG/O',
  },
  LINKS: {
    CLIENTS_PORTAL: 'https://clientes.financierapoint.com.mx',
  }
};

// TODO: No guardar credenciales en el front
export const Config = process.env.REACT_APP_ENVIRONMENT === "prod" ? prod : dev;