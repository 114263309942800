import TextAnimation from '../../animations/text-animation';
import Container from '../generic/containers/container';
import ContentContainer from '../generic/containers/content-container';
import IconCard, { IconCardType } from '../generic/lists/icon-card';
import Paragraph from '../generic/text/paragraph';
import SecondTextHeader from "../generic/text/second-text-header";

type StatsComponentProps = {
  numberCredits: string;
  text: string
  cards: IconCardType[];
}

const StatsComponent = ({
  numberCredits,
  cards,
  text
}: StatsComponentProps) => {

  return (
    <>
      <Container name='stats'>
        <ContentContainer>
          <TextAnimation>
            <SecondTextHeader className="md:my-4 pb-4 md:pb-0 text-center">
              {'Más de '}
              <span className="text-purple-primary">{numberCredits}</span>
              {' créditos otorgados'}
            </SecondTextHeader>
          </TextAnimation>
          <Paragraph className='text-center'>{text}</Paragraph>
        </ContentContainer>
        <IconCard cards={cards} />
      </Container>
    </>
  );
};

export default StatsComponent;