
type ToggleButtonProps = {
    children: React.ReactNode;
    className?: string;
    onClick: () => void;
};

const ToggleButton = ({
    children, 
    className,
    onClick,
}: ToggleButtonProps) => {
    const css = className ?? "";
    return (
        <button 
            className={`${css} rounded-full text-md font-barlow font-bold text-gray-hard p-4 w-1/2 transition duration-300 focus:outline-none`}
            onClick={onClick}
        >
            {children}
        </button>
    );
}

export default ToggleButton;