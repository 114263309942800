import ContentContainer from "../generic/containers/content-container";

const PrivacyPoliciy = () => {
  return (
    <ContentContainer>

      <div className='pb-4 text-justify'>
        <p>
          FINANTIAL RICARPOINT SAPI DE CV SOFOM ENR, mejor conocido como FINANCIERA POINT, con domicilio en calle AYACAHUITE 112, colonia VILLAS DEL ALAMO, ciudad MINERAL DE LA REFORMA, municipio o delegación MINERAL DE LA REFORMA, c.p. 42184, en la entidad de HIDALGO, país MEXICO, es el responsable del uso y protección de sus datos personales, y al respecto le informamos lo siguiente:
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p className='font-bold'>
          ¿Para qué fines utilizaremos sus datos personales?.
        </p>
        <ul className='list-disc pl-0 md:pl-4 lg:pl-8 py-4 text-left'>
          <li>Prospección comercial</li>
        </ul>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que <span className='font-bold'> no son necesarias </span> para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:
        </p>
        <ul className='list-disc pl-0 md:pl-4 lg:pl-8 py-4 text-left'>
          <li>OTORGAMIENTO DE CREDITO</li>
        </ul>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          En caso de que no desee que sus datos personales se utilicen para estos fines secundarios, indíquelo a continuación:
        </p>
        <p>
          No consiento que mis datos personales se utilicen para los siguientes fines:
        </p>
        <p>
          [ ] OTORGAMIENTO DE CREDITO
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p className='font-bold'>
          ¿Para qué fines utilizaremos sus datos personales?.
        </p>
        <p>
          Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:
        </p>
        <ul className='list-disc pl-0 md:pl-4 lg:pl-8 py-4 text-left'>
          <li>Nombre</li>
          <li>Estado Civil</li>
          <li>Registro Federal de Contribuyentes(RFC)</li>
          <li>Clave única de Registro de Población (CURP)</li>
          <li>Lugar de nacimiento</li>
          <li>Fecha de nacimiento</li>
          <li>Nacionalidad</li>
          <li>Domicilio</li>
          <li>Teléfono particular</li>
          <li>Teléfono celular</li>
          <li>Correo electrónico</li>
          <li>Edad</li>
          <li>Fotografía</li>
          <li>Puesto o cargo que desempeña</li>
          <li>Referencias laborales</li>
          <li>Trayectoria educativa</li>
          <li>Ingresos</li>
          <li>Egresos</li>
          <li>Cuentas bancarias</li>
          <li>Número de tarjetas de crédito</li>
        </ul>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          Además de los datos personales mencionados anteriormente, para las finalidades informadas en el presente aviso de privacidad utilizaremos los siguientes datos personales considerados como sensibles, que requieren de especial protección:
        </p>
        <ul className='list-disc pl-0 md:pl-4 lg:pl-8 py-4 text-left'>
          <li>Religión que profesa</li>
          <li>Pertenencia a un sindicato</li>
          <li>Estado de salud físico presente, pasado o futuro</li>
        </ul>
      </div>

      <div className='pb-4 text-justify'>
        <p className='font-bold'>
          ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso?
        </p>
        <p>
          Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar la solicitud respectiva a través del siguiente medio:
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          LLAMADO AL NUMERO TELEFONICO 771 186 1415
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          Con relación al procedimiento y requisitos para el ejercicio de sus derechos ARCO, le informamos lo siguiente:
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su caso, su representante, así como la personalidad este último?
        </p>
        <p>
          CORREO ELECTRONICO
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          b) ¿Qué información y/o documentación deberá contener la solicitud?
        </p>
        <p>
          INE
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          c) ¿En cuántos días le daremos respuesta a su solicitud?
        </p>
        <p>
          30 DIAS HABILES
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          d) ¿Por qué medio le comunicaremos la respuesta a su solicitud?
        </p>
        <p>
          CORREO ELECTRONICO
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          e) ¿En qué medios se pueden reproducir los datos personales que, en su caso, solicite?
        </p>
        <p>
          ELECTRONICOS
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          a) Nombre de la persona o departamento de datos personales: CARLOS ALBERTO HERNANDEZ MEJIZ
        </p>
        <p>
          b) Domicilio: calle AV. CONSTITUYENTES 401, colonia CONSTITUCION, ciudad PACHUCA, municipio o delegación PACHUCA, c.p. 42080, en la entidad de HIDALGO, país MEXICO
        </p>
        <p>
          c) Correo electrónico: finantial.ricartpoint@hotmail.com
        </p>
        <p>
          d) Número telefónico: 7711861415
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p className='font-bold'>
          Usted puede revocar su consentimiento para el uso de sus datos personales
        </p>
        <p>
          Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          Para revocar su consentimiento deberá presentar su solicitud a través del siguiente medio:
        </p>
        <p>
          771 186 1415
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p>
          Para conocer el procedimiento y requisitos para la revocación del consentimiento, ponemos a su disposición el siguiente medio:
        </p>
        <p>
          correo electrónico
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p className='font-bold'>
          ¿Cómo puede limitar el uso o divulgación de su información personal?
        </p>
        <p>
          Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios:
        </p>
        <p>
          envio de un correo electrónico
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p className='font-bold'>
          El uso de tecnologías de rastreo en nuestro portal de internet
        </p>
        <p>
          Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y experiencia al navegar en nuestra página. Los datos personales que recabamos a través de estas tecnologías, los utilizaremos para los siguientes fines:
        </p>
        <p>
          PARA ADMINISTRAR Y OPERAR LOS SERVICIOS Y PRODUCTOS FINANCIEROS QUE SOLICITA O CONTRATA CON NOSOTROS
        </p>
        <p>
          Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:
        </p>
        <p>
          Identificadores, nombre de usuario y contraseñas de una sesión
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p className='font-bold'>
          ¿Cómo puede conocer los cambios en este aviso de privacidad?
        </p>
        <p>
          El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas.
        </p>
        <p>
          Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, a través de: ENVIO DE CORREOS ELECTRONICOS.
        </p>
        <p>
          El procedimiento a través del cual se llevarán a cabo las notificaciones sobre cambios o actualizaciones al presente aviso de privacidad es el siguiente:
        </p>
        <p>
          POR MEDIO DE CORREO ELECTRONICO
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p className='font-bold'>
          Su consentimiento para el tratamiento de sus datos personales
        </p>
        <p>
          Consiento que mis datos personales sean tratados de conformidad con los términos y condiciones informados en el presente aviso de privacidad.[ ]
        </p>
      </div>

      <div className='pb-4 text-justify'>
        <p className='text-right'>
          Última actualización: 03/11/2022
        </p>
      </div>
    </ContentContainer>
  );
};

export default PrivacyPoliciy
