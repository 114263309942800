import { useAnimation, useCycle } from "framer-motion";
import { useState } from 'react';

const useAnimatedNavToggler = () => {

    const [showNavLinks, setShowNavLinks] = useState<boolean>(false);
    const [x, cycleX] = useCycle("0%", "150%");
    const animation = useAnimation();

    const toggleNavbar = () => {
        setShowNavLinks(!showNavLinks);
        animation.start({ x: x, display: "block" });
        cycleX();
    };

    return { showNavLinks,animation, toggleNavbar }
};

export default useAnimatedNavToggler;