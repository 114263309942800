
type NavToogleProps = {
    children: React.ReactNode;
    className?: string;
    onClick: () => void;
};

const NavToggle = ({
    children,
    className,
    onClick
}: NavToogleProps) => {

    const css = className ?? "";
    return (
        <button 
            className={`md:hidden z-20 mr-4 transition duration-300 border-slate-600 focus:outline-none focus:text-purple-primary hover:text-purple-primary ${css}`}  
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default NavToggle;