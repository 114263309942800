import TextAnimation from '../../animations/text-animation';
import MobileImg from "../../assets/images/benefits.png";
import { Config } from '../../utils/environment-constants';
import RoundedButton from '../generic/buttons/rounded-button';
import ContentContainer from '../generic/containers/content-container';
import LeftColumn from "../generic/containers/left-colum";
import RightColumn from '../generic/containers/right-colum';
import TwoColumnImage from '../generic/containers/two-column-image';
import MobileImage from '../generic/images/mobile-image';
import Paragraph from '../generic/text/paragraph';
import SecondTextHeader from '../generic/text/second-text-header';

type MyAccountProps = {
  titlePrimary: string;
  title: string;
  text: string;
  button?: string;
}

const MyAccount = ({
  titlePrimary,
  title,
  text,
  button,
}: MyAccountProps) => {

  const openInNewTab = (href:string) => {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: href,
    })
    .click();
  }

  return (
    <div id='benefits' className='md:my-16 lg:my-28'>
      <TwoColumnImage 
        className="bg-right lg:bg-my-account-background"
      >
        <LeftColumn className='lg:px-8'>                    
          <ContentContainer>
            <TextAnimation>
              <SecondTextHeader className="my-4 pb-4">
                {title}
                <span className="text-purple-primary">{titlePrimary}</span>
              </SecondTextHeader>
            </TextAnimation>
            <MobileImage img={MobileImg} className="!ml-0 !mr-[2rem]" />
            <div className='inline-block mx-auto'>
              <Paragraph>
                {text}
              </Paragraph>
            </div>
            {
              button && (
                <div className='bloc'>
                  <TextAnimation>
                    <RoundedButton className="my-6 md:my-8" onClick={() => openInNewTab(Config.LINKS.CLIENTS_PORTAL)}>
                      {button}
                    </RoundedButton>
                  </TextAnimation>
              </div>
              )
            }
          </ContentContainer>
        </LeftColumn>
        <RightColumn/> 
      </TwoColumnImage>
    </div>
  );
};

export default MyAccount;