import { Slider, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextAnimation from '../../animations/text-animation';
import MobileImg from "../../assets/images/hero.png";
import { Cotizaciones, CotizadorResponse, Cuotas, EsquemasPago } from '../../model/quoter-model';
import { useApis } from '../../providers/apis-provider';
import { mapGetQuote } from '../../services/credits.service';
import RoundedButton from '../generic/buttons/rounded-button';
import ToggleButton from '../generic/buttons/toggle-button';
import ContentContainer from '../generic/containers/content-container';
import LeftColumn from "../generic/containers/left-colum";
import RightColumn from '../generic/containers/right-colum';
import TwoColumnImage from '../generic/containers/two-column-image';
import MobileImage from '../generic/images/mobile-image';
import Paragraph from '../generic/text/paragraph';
import SecondTextHeader from '../generic/text/second-text-header';
import TextHeader from '../generic/text/text-header';

type QuotationComponentProps = {
  titlePrimary: string;
  title: string;
  button: string;
}

const QuotationComponent = ({
  titlePrimary,
  title,
  button
}: QuotationComponentProps) => {

  const [amount, setAmount] = useState(7000);
  const [data, setData] = useState(emptyCotizador);
  const [cuotas, setCuotas] = useState([emptyCuota]);
  const [cuotaSelect, setCuotaSelect] = useState(emptyCuota);
  const [paymentScheme, setPaymentScheme] = useState({
    key: "D",
    text: "Pagos Diarios",
  }); //TODO: ENUM Backend

  const navigate = useNavigate();
  const { newRequest } = useApis();

  // FIXME: any
  const handlePaymentScheme = (newValue: any) => {
    setPaymentScheme(newValue);
  };

  // FIXME: any
  const handleAmount = (event: any, newValue: any) => {
    setAmount(newValue);
  };

  const handleChange = () => {
    const cuotasFiltered = data?.cotizaciones
      .find(cot => cot?.montoCredito === amount)?.esquemasPago
      .find(e => e?.clave === paymentScheme.key)?.cuotas

    setCuotas(cuotasFiltered ?? [emptyCuota]);
    handleQuote(cuotasFiltered ? cuotasFiltered[0] : emptyCuota);
  };

  const handleQuote = (cuota: Cuotas) => {
    setCuotaSelect(cuota);
  };

  useEffect(
    () => handleChange(), 
    // eslint-disable-next-line 
    [data, cuotas, paymentScheme, amount]
  );

  useEffect(
    () => {
      // FIXME: Se realizan peticiones dobles
      if (cuotas.length < 2) {
        // FIXME: Map response
        newRequest(mapGetQuote())
          .then((response: any) => setData(response.data.resultado));
      }
    },
    // eslint-disable-next-line 
    []
  );

  return (
    <>
      <TextAnimation>
        <SecondTextHeader className="text-center w-full md:my-4 md:pb-0 lg:mt-16">
          {title}
          <span className="text-purple-primary">{titlePrimary}</span>
        </SecondTextHeader>
      </TextAnimation>

      <TwoColumnImage
        name='quotation'
        className='lg:bg-hero-background !h-fit !max-h-fit'
      >
        <LeftColumn />
        <MobileImage img={MobileImg} />

        {/* Validation if data don't load correctly */}
        {
          cuotas.length < 2 
            ? (
              <ContentContainer>
                <Paragraph>
                  {"No pudimos mostrar correctamente el cotizador."}
                </Paragraph>
                <Paragraph>
                  {"Intentalo nuevamente recargando la página"}
                </Paragraph>
                <RoundedButton className='my-10' onClick={() => window.location.reload()}>
                  {"Cargar cotizador"}
                </RoundedButton>
              </ContentContainer>
            ) 
            : (
              <RightColumn className='self-start items-center justify-center p-4'>
                <div className='border-2 border-black rounded-full flex items-center justify-center w-full md:w-1/2'>
                {
                  paymentsScheme.map((payment, index) => (
                    <React.Fragment key={index}>
                    {
                      payment.key === paymentScheme.key ? (
                        <ToggleButton
                          onClick={() => handlePaymentScheme(payment)}
                          className={'bg-gray-soft-hover text-white'}
                        >
                          {payment.text}
                        </ToggleButton>
                      ) : (
                        <ToggleButton onClick={() => handlePaymentScheme(payment)}>
                          {payment.text}
                        </ToggleButton>
                      )
                    }
                    </React.Fragment>
                  ))
                }
                </div>

                <div className='mt-20 w-full'>
                  <PrettoSlider
                    marks={marks}
                    valueLabelDisplay="on"
                    defaultValue={amount} // FIXME: Obtener de backend
                    step={data.incremento}
                    min={data.montoMinimo}
                    max={data.montoMaximo}
                    onChangeCommitted={handleAmount}
                  />
                </div>
                
                  <div className='my-8 w-full text-center rounded-lg text-gray-hard bg-white shadow-2xl md:last:mr-0'>
                    <div className='flex flex-col justify-center items-center py-8 -mx-8 rounded-t-lg'>
                      <TextAnimation>
                        <SecondTextHeader className='py-4 !text-purple-primary'>
                          {`$${amount / 1000},000`}
                        </SecondTextHeader>
                        <Paragraph className='justify-center items-center'>
                          {`${cuotaSelect.numPagos} ${paymentScheme.text} de $${(cuotaSelect.cuotaRecurrente)}`}
                        </Paragraph>
                      </TextAnimation>
                    </div>
                  </div>

                <div className='w-full flex flex-wrap justify-center items-center'>
                {
                  cuotas.map((cuota, index) => (
                    <div
                      className='flex items-center p-2 m-2 rounded-lg text-center text-black bg-white shadow-2xl max-w-[120px] min-h-[120px] transition-transform duration-300 hover:cursor-pointer transform hover:scale-105 md:p-4 md:m-4 md:max-w-[175px] md:min-h-[175px] '
                      key={index}
                      onClick={() => handleQuote(cuota)}
                    >
                      <div className='flex flex-col justify-center items-center'>
                      {
                        cuota.numPagos === cuotaSelect.numPagos ? (
                          <>
                            <TextHeader className='!text-purple-primary'>
                              {`${cuota.numPagos}`}
                            </TextHeader>
                            <Paragraph className='transition duration-300 !text-purple-primary !text-lg text-center'>
                              {`${paymentScheme.text} de $${cuota.cuotaRecurrente}`}
                            </Paragraph>
                          </>
                        ) : (
                          <>
                            <TextHeader>
                              {`${cuota.numPagos}`}
                            </TextHeader>
                            <Paragraph className='!text-lg transition duration-300 text-center'>
                              {`${paymentScheme.text} de $${cuota.cuotaRecurrente}`}
                            </Paragraph>
                          </>
                        )
                      }
                      </div>
                    </div>
                  ))
                }
                </div>

                <div className='flex justify-center items-center mt-8'>
                  <TextAnimation>
                    <RoundedButton
                      onClick={
                        () => navigate("/cotizador/formulario", {
                          state: {
                            paymentScheme: paymentScheme.key,
                            amount: amount,
                            paymentsNumber: cuotaSelect.numPagos,
                            interestRate: cuotaSelect.tasaInteres
                          }
                        })
                      }
                    >
                      {button}
                    </RoundedButton>
                  </TextAnimation>
                </div>
              </RightColumn>
            )
        }
      </TwoColumnImage>
    </>
  );
};

export default QuotationComponent;

export const marks = [
  {
    value: 5000,
    label: '$5,000'
  },
  {
    value: 15000,
    label: '$15,000'
  }
];

export const paymentsScheme = [
  {
    key: "D",
    text: "Pagos Diarios",
  },
  {
    key: "S",
    text: "Pagos Semanales",
  }
];

export const emptyCuota: Cuotas = {
  numPagos: 0,
  tasaInteres: 0,
  cuotaRecurrente: 0,
};

export const emptyEsquemaPago: EsquemasPago = {
  clave: "D",
  descripcion: "Pagos Diarios",
  cuotas: [emptyCuota],
}

export const emptyCotizacion: Cotizaciones = {
  montoCredito: 7000,
  comision: 0,
  esquemasPago: [emptyEsquemaPago],
};

export const emptyCotizador: CotizadorResponse = {
  montoMinimo: 5000,
  montoMaximo: 15000,
  incremento: 1000,
  esquemasPago: "PAGOS_DIARIOS",
  cotizaciones: [emptyCotizacion],
};

export const PrettoSlider = styled(Slider)({
  color: `#8d43ed`, //FIXME:
  height: 15,
  width: `80% !important`,
  left: "50% !important",
  transform: "translateX(-50%)",
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 34,
    width: 34,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-markLabel': {
    fontSize: 22,
    fontFamily: 'Barlow',
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 36,
    fontFamily: 'Barlow',
    fontWeight: 800,
    background: 'unset',
    marginTop: '20px',
    width: 62,
    height: 62,
    color: `#8d43ed`, //FIXME:
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});