import { ApiModel } from "../model/apis/apis-model";
import { RequestModel } from "../model/request-model";
import { Config } from "../utils/environment-constants";

const POINT_PATH = '/point/v1';

export const mapGetQuote = ():ApiModel => ({
  endpoint: `${Config.API.URL}${POINT_PATH}/cotizador/pagina-web`,
  verb: "GET"
});

export const mapPostInterest = (
  request: RequestModel,
):ApiModel => ({
  endpoint: `${Config.API.URL}${POINT_PATH}/interesado`,
  body: JSON.stringify(request),
  verb: "POST"
}); 