import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAnimatedNavToggler from '../../../animations/nav-toggler-animation';
import logo from "../../../assets/images/pointmx.svg";
import { Config } from '../../../utils/environment-constants';
import RoundedButton from '../../generic/buttons/rounded-button';
import DesktopNavLinks from "./desktop-navlinks";
import Header from './header';
import MobileNavLinksContainer from "./mobile-navlinks-container";
import NavLink from "./nav-link";
import NavLinks from './nav-links';
import NavToggle from './nav-toggle';

const HeaderComponent = () => {

  const [navbar, setNavbar] = useState(false)
  const navigate = useNavigate();
  
  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss = collapseBreakPointCssMap['md'];

  const changeBackground = () => {
    if (window.scrollY >= 50) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", changeBackground)
  })

  const defaultLogoLink = (
    <NavLink className='flex items-center font-black border-b-0 text-2xl! p-4 ml-0! z-10' href="/">
      <img src={logo} alt="pointmx" className="w-40"/>
    </NavLink>
  );

  const defaultLinks = (className?: string) => {
    return (
      <>
        <NavLinks className={className}>
          <NavLink 
            href={Config.LINKS.CLIENTS_PORTAL}
            target={"_blank"}
          >
            MI CUENTA
          </NavLink>
          <RoundedButton onClick={() => navigate("/cotizador")}>
            {"Cotiza tu crédito"}
          </RoundedButton>
        </NavLinks>
      </>
    );
  };

  return (
    <div className={`fixed max-w-screen-2xl w-full z-20 py-2 ${navbar ? 'bg-white' : 'bg-transparent '}`}>
      <Header>
        <DesktopNavLinks className={collapseBreakpointCss.desktopNavLinks}>
          <>
            {defaultLogoLink}
            {defaultLinks('z-10 p-3')}
          </>
        </DesktopNavLinks>

        <MobileNavLinksContainer className={collapseBreakpointCss.mobileNavLinksContainer}>
          {defaultLogoLink}

          {/* TODO: Descomentar cuando se agreguen secciones */}
          <motion.div
            initial={{ x: "150%", display: "none" }} 
            animate={animation} 
            className={'md:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border-[0.5px] border-gray-soft text-center rounded-lg text-gray-hard bg-white'}
          >
            {defaultLinks('flex flex-col items-center')}
          </motion.div>

          <NavToggle 
            className={showNavLinks ? "open" : "closed"}
            onClick={toggleNavbar} 
          >
            {
              showNavLinks 
                ? <CloseIcon className="w-6 h-6" /> 
                : <MenuIcon className="w-6 h-6" />
            }
          </NavToggle>
        </MobileNavLinksContainer>
      </Header>
    </div>
  );
};

export default HeaderComponent;

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: 'sm:hidden',
    desktopNavLinks: 'sm:flex',
    mobileNavLinksContainer: 'sm:hidden'
  },
  md: {
    mobileNavLinks: 'md:hidden',
    desktopNavLinks: 'md:flex',
    mobileNavLinksContainer: 'md:hidden'
  },
  lg: {
    mobileNavLinks: 'lg:hidden',
    desktopNavLinks: 'lg:flex',
    mobileNavLinksContainer: 'lg:hidden'
  },
  xl: {
    mobileNavLinks: 'lg:hidden',
    desktopNavLinks: 'lg:flex',
    mobileNavLinksContainer: 'lg:hidden'
  }
};