
type LinearButtonProps = {
    children: React.ReactNode;
    className?: string;
    onClick: () => void;
};

const LinearButton = ({
    children, 
    className,
    onClick,
}: LinearButtonProps) => {
    const css = className ?? "";
    return (
        <button 
            className={`${css} text-purple-primary py-3 cursor-pointer
                font-normal font-barlow text-left underline decoration-2 text-xl md:text-3xl`}
            onClick={onClick}
        >
            {children}
            {"→"}
        </button>
    );
}

export default LinearButton;