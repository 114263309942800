import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import ListAnimation from '../../../animations/list-animation';
import Paragraph from '../text/paragraph';

type CheckTextProps = {
  checks: string[];
  className?: string;
  xInit: string;
};

const CheckText = ({
  checks,
  className,
  xInit
}: CheckTextProps) => {
  const css = className ?? "";
  return (
    <>
    {
      checks.map((text, index) => (
        <React.Fragment key={index}>
          <ListAnimation xInit={xInit}>
            <div className={`${css} flex items-center`}>
              <CheckIcon className={`mx-4 text-purple-primary md:!w-12 md:!h-12`} />
              <Paragraph>{text}</Paragraph>
            </div>
          </ListAnimation>
        </React.Fragment>
      ))
    }
    </>
  );
}

export default CheckText;