import { useEffect } from 'react'
import HeroQuotationComponent from '../components/quotation/hero-quotation'
import QuotationComponent from '../components/quotation/quotation'

const QuotationPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <HeroQuotationComponent
        title='Somos '
        titlePrimary='transparentes'
        subTitle='Tú mismo puedes revisar las diferentes opciones de financiamiento que tenemos para ti.'
      />
      <QuotationComponent
        title='Cotizador en '
        titlePrimary='línea'
        button='Solicitar crédito'
      />
    </>
  )
}

export default QuotationPage
