import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import TwitterIcon from '@mui/icons-material/Twitter'
import logo from '../../../assets/images/point-white.png'

const Footer = () => {
  return (
    <>
      <div className='flex flex-col mb-24 md:mb-0'>
        <div className='flex bg-purple-primary justify-center px-4 py-4 md:px-12 md:py-12'>
          <div className='flex flex-col justify-center items-center md:flex-row'>
            <img src={logo} alt='' className='h-16 md:h-20 px-4' />
            <div className='p-4 md:px-20'>
              <a
                href='https://www.facebook.com/point.financiera/'
                target={'_blank'}
                rel='noreferrer'
              >
                <FacebookIcon className='!h-8 !w-8 md:!h-12 md:!w-12 text-white mx-4' />
              </a>
              <a
                href='https://instagram.com/point.financiera?r=nametag'
                target={'_blank'}
                rel='noreferrer'
              >
                <InstagramIcon className='!h-8 !w-8 md:!h-12 md:!w-12 text-white mx-4' />
              </a>
              <a
                href='https://twitter.com/pointfinanciera?s=11&t=X2j8SPgYzSRAEGDHFpYJjQ'
                target={'_blank'}
                rel='noreferrer'
              >
                <TwitterIcon className='!h-8 !w-8 md:!h-12 md:!w-12 text-white mx-4' />
              </a>
            </div>
          </div>
        </div>
        <div className='bg-purple-hard text-justify text-white text-xs font-barlow px-12 py-16 md:text-sm'>
          {
            'Finantial Ricartpoint, Sociedad Anónima Promotora de Inversión de Capital Variable, Sociedad Financiera de Objeto Múltiple, Entidad No Regulada ("Point.mx"), para su constitución y operación con tal carácter, no requiere de autorización de la Secretaría de Hacienda y Crédito Público, no obstante, se encuentra sujeta a la supervisión de la Comisión Nacional Bancaría y de Valores, únicamente para efectos de los dispuesto por el artículo 56 de la Ley General de Organizaciones y Actividades Auxiliares de Crédito. Point.mx ©. Todos los derechos reservados. Prohibida la reproducción, total o parcial del contenido de este sitio.'
          }
          <div className='mt-4'>

            <a href='/aviso-privacidad'>
              {"Al hacer uso de nuestra página esta de acuerdo con nuestro "}
              <span className='font-bold'>
                {"Aviso de privacidad"}
              </span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
