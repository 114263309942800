import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import StorefrontIcon from '@mui/icons-material/Storefront';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import { useEffect } from 'react';
import BenefitsComponent from '../components/main/benefits';
import HeroComponent from '../components/main/hero';
import MyAccount from '../components/main/MyAccount';
import StatsComponent from '../components/main/stats';
import StepsComponent from '../components/main/steps';
import StepsJoinComponent from '../components/main/steps-join';
import WhyUsComponent from '../components/main/why-us';
import IWantIt from '../components/shared/footer/i-want-it';

const HomePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <>
      <HeroComponent 
        titlePrimary='Dinero '
        title='para crecer tu negocio'
        subTitle='Tú preocúpate por vender y hacer lo tuyo, nosotros te ayudamos a aprovechar tu financiamiento'
        checkListText={[
          'Tu dinero en menos de 48 hrs',
          'Pocos requisitos',
          'Trámite rápido y seguro',
        ]}
      />
      <MyAccount
        title='Entra a tu cuenta '
        titlePrimary='aquí'
        text='Ya puedes consultar tus movimientos y descargar tu estado de cuenta'
        button='Consultar'
      />
      <StepsComponent
        firstAmount='$5,000'
        secondAmount='$50,000'
        steps={[
          { text:'Entra a nuestro cotizador en línea'},
          { text:'Revisa el esquema de pago que se ajusta más a tu capacidad de pago'},
          { text:'Solicita tu crédito', textBold: '¡Así de fácil!'},
        ]}
      />
      <StatsComponent 
        text='Cada día otorgamos nuevos créditos que están ayudando a los negocios de nuestros clientes'
        numberCredits='250'
        cards={[
        {
          count:300,
          body:'Clientes satisfechos',
          icon: <TagFacesIcon className='!h-28 !w-28 text-white'/>
        },
        {
          count:150,
          body:'Negocios ayudados',
          icon: <StorefrontIcon className='!h-28 !w-28 text-white'/>
        },
        {
          count:500,
          body:'Créditos otorgados',
          icon: <PointOfSaleIcon className='!h-28 !w-28 text-white'/>
        }
        ]}
      />
      <BenefitsComponent
        titlePrimary='Beneficios '
        title='de solicitar un crédito con nosotros'
        checkListText={[
          'Fácil',
          'Rápido',
          'Seguro',
          'Soporte',
          'Accesible',
          'Configurable',
        ]}
      />
      <StepsJoinComponent
        firstTitlePrimary='Anímate.'
        title='En pocos pasos podrás formar parte de nuestra'
        secondTitlePrimary='familia'
        button='Ir al cotizador en línea'
        steps={[
          { text:'Ingresa a nuestro cotizador en línea o márcanos a cualquiera de nuestros números'},
          { text:'Selecciona la cantidad que deseas que te prestemos y te pediremos algunos datos de tu negocio'},
          { text:'Ya que hayamos validado tus datos te enviaremos la información necesaria para terminar el trámite'},
        ]}
      />
      <WhyUsComponent />
      <IWantIt/>
    </>
  );
};

export default HomePage;