type TextHeaderProps = {
  children: React.ReactNode
  className?: string
}

const TextHeader = ({ children, className }: TextHeaderProps) => {
  const css = className ?? ''
  return (
    <h1
      className={`text-gray-hard font-gill-sans text-5xl leading-none max-w-3xl break-words ${css} md:text-6xl lg:text-7xl`}
    >
      {children}
    </h1>
  )
}

export default TextHeader
